import { stylesheet } from 'typestyle';
import { Color } from 'styles/base';
import { CSS } from 'styles/types';

export const textBase: CSS = {
  fontFamily: 'Helvetica, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontStretch: 'normal',
  letterSpacing: 'normal',
  color: Color.eclipse,
  margin: 0
};

export const textBaseS: CSS = {
  ...textBase,
  fontFamily: 'Prata'
};

export const headingOne: CSS = {
  ...textBase,
  fontFamily: 'Prata',
  fontSize: 30,
  fontWeight: 'bold',
  lineHeight: '38px',
  letterSpacing: -0.1
};

export const headingOneS: CSS = {
  ...textBaseS,
  fontSize: 30,
  fontWeight: 'bold',
  lineHeight: 1.27,
  letterSpacing: -0.1
};

export const headingTwo: CSS = {
  ...textBase,
  fontFamily: 'Prata',
  fontSize: 30,
  lineHeight: '38px',
  letterSpacing: '-0.1px'
};

export const headingThree: CSS = {
  ...textBase,
  fontFamily: 'Lato',
  fontSize: 17,
  fontWeight: 500,
  lineHeight: '22px'
};

export const headingFour: CSS = {
  ...textBase,
  fontFamily: 'Lato',
  fontSize: 15,
  lineHeight: '20px',
  color: Color.textDark
};

export const headingFive: CSS = {
  ...textBase,
  fontFamily: 'Lato',
  fontSize: 11,
  lineHeight: 1.64
};

export const paragraph: CSS = {
  ...textBase,
  fontFamily: 'Lato',
  fontSize: 17,
  lineHeight: '22px'
};

export const paragraphS: CSS = {
  ...textBaseS,
  fontFamily: 'Lato',
  fontSize: 17,
  lineHeight: 1.29
};

export const small: CSS = {
  ...headingFour,
  fontFamily: 'Prata',
  margin: 0,
  display: 'block'
};

export const tiny: CSS = {
  ...headingFive,
  fontSize: 10,
  letterSpacing: 0
};

export const label: CSS = headingFive;

export const largeButtonText: CSS = {
  ...textBase,
  fontFamily: 'Lato',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '22px',
  marginBottom: 0
};

export const smallButtonText: CSS = {
  ...paragraph,
  marginBottom: 0
};

export const strong: CSS = {
  color: Color.blueSky,
  fontWeight: 'bold'
};

export default stylesheet({
  headingOne,
  headingOneS,
  headingTwo,
  headingThree,
  headingFour,
  headingFive,
  paragraph,
  paragraphS,
  small,
  tiny,
  label,
  strong
});
