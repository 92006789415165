import { style, media } from 'typestyle';
import { color as makeColor } from 'csx';
import { CSS, MediaCSS, MarginCSS, FlexCSS, TypographyCSS } from 'styles/types';

export const fadeColor = (color: string, fadeTo: number): string => makeColor(color).fade(fadeTo).toString();

export const Color = {
  blueSky: '#1B4EF1',
  nightSky: '#0C1123',
  cloud: '#F1F4FF',
  eclipse: '#252A3D',
  moon: '#62666E',
  fog: '#DFE6FF',
  mist: '#FCFCFF',
  attention: '#FF5B5E',
  errorText: '#B90000',
  success: '#00B989',
  booked: '#00B989',
  infoBG: '#FFFBF2',
  incomeText: '#C98013',
  income: '#FFC452',
  white: '#FFFFFF',
  black: '#000000',
  disabled: '#E2E7F1',
  backgroundGrey: '#F5F7F8',
  borderGrey: '#BCBCBC',
  buttonHoverBlue: '#2B7CF9',
  buttonActiveBlue: '#0F2EEC',
  buttonDark: '#111111',
  buttonDarkHover: '#FFFD5F',
  buttonDarkDisabled: '#EFEFF0',
  buttonDarkActive: '#989898',
  textDark: '#111111'
};

export const Media = {
  phone: { minWidth: 0, maxWidth: 479 },
  tablet: { minWidth: 480, maxWidth: 767 },
  mobile: { minWidth: 0, maxWidth: 767 },
  desktop: { minWidth: 768 }
};

/**
 * Transition speeds based off of material design guidelines
 * https://material.io/design/motion/speed.html
 */
export const Transition = {
  small: { expand: '100ms', collapse: '100ms' },
  medium: { expand: '250ms', collapse: '200ms' },
  large: { expand: '300ms', collapse: '250ms' }
};

export const mediaStyle = ({ shared, phone, tablet, mobile, desktop }: MediaCSS) =>
  style(
    shared,
    phone && media(Media.phone, phone),
    tablet && media(Media.tablet, tablet),
    mobile && media(Media.mobile, mobile),
    desktop && media(Media.desktop, desktop)
  );

/**
 * Isolate CSS props for margins. This function can be used for styled
 * components that take margin props, to ensure non-CSS props are filtered out
 * of the CSS object.
 */
export const extractMarginCSS = ({ marginTop, marginBottom, marginLeft, marginRight }: CSS): MarginCSS => ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight
});

/**
 * Isolate CSS props for flexbox. This function can be used for styled
 * components that take flexbox layout props, to ensure non-CSS props are
 * filtered out of the CSS object.
 */
export const extractFlexCSS = ({
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  flexGrow,
  flexShrink
}: CSS): FlexCSS => ({
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  flexGrow,
  flexShrink
});

/** Isolate CSS props for typography. */
export const extractTypographyCSS = ({ textAlign, fontStyle, fontWeight, fontSize }: CSS): TypographyCSS => ({
  textAlign,
  fontStyle,
  fontWeight,
  fontSize
});

/**
 * In some cases Safari will shrink a flexbox to be smaller than its contents,
 * rendering the flexbox contents on top of other elements that should be above
 * or below in the flow. Setting these properties should fix the bug, but the
 * fix can't be added to all flex layouts globally.
 *
 * See https://github.com/philipwalton/flexbugs#flexbug-1
 */
export const fixMinimumFlexSizeIOS: CSS = {
  flexShrink: 0,
  flexBasis: 'auto'
};

// TODO: remove
export const ErrorMessageBanner = style({
  background: Color.errorText,
  width: '100%',
  padding: '5px 10px',
  marginBottom: 10,
  borderRadius: 8
});
