import axios from 'axios';
import applyCaseMiddleware, * as applyConverters from 'axios-case-converter';
import { DateString } from 'utils/dates';
import { makeFormData } from 'api/utils';
import {
  BackendTenant,
  Tenant,
  Calendar,
  Booking,
  Property,
  StartStepPayload,
  CompleteStepPayload,
  OnboardingApplication,
  ResidentOnboardingStepType,
  SessionErrorCode
} from 'api/types';

// This is a temp work around because we're seperating out tenant
function remapTenant(backendTenant: BackendTenant): Tenant {
  const {
    firstName,
    lastName,
    phone,
    id: personId,
    account: { id: accountId, email }
  } = backendTenant.person;

  const clone = { ...backendTenant, firstName, lastName, phoneNumber: phone, emailAddress: email, personId, accountId };
  // delete clone.person

  return clone;
}

function remapTenantToBackend(tenant: Tenant): BackendTenant {
  const { firstName, lastName, phoneNumber, emailAddress, personId, accountId } = tenant;

  const clone = {
    ...tenant,
    person: { id: personId, firstName, lastName, phone: phoneNumber, account: { id: accountId, email: emailAddress } }
  };

  return clone;
}

const apiClient = applyCaseMiddleware(axios.create());

const requestPasswordReset = (emailAddress: string): Promise<void> =>
  apiClient
    .post('/api/password_resets', { emailAddress })
    .then(() => {
      return;
    })
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const resetPassword = (password: string, passwordConfirmation: string, token: string): Promise<void> =>
  apiClient
    .put(`/api/password_resets/${token}`, { password, passwordConfirmation })
    .then(() => {
      return;
    })
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const createPassword = (password: string, token: string): Promise<void> =>
  apiClient
    .post('/api/activation', { invitationToken: token, password })
    .then(() => {
      return;
    })
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const createSession = (email: string, password: string): Promise<void> =>
  apiClient
    .post('/api/api/sessions', { session: { email, password } })
    .then(() => {
      return;
    })
    .catch(({ response }) => {
      throw response.data.error.code as SessionErrorCode;
    });

const getTenant = (): Promise<Tenant> =>
  apiClient
    .get('/api/tenant')
    .then(({ data }) => remapTenant(data.tenant))
    .catch(({ response }) => {
      throw response.data.error.code as SessionErrorCode;
    });

const getCalendar = (): Promise<Calendar> => apiClient.get('/api/calendar').then(({ data }) => data);

const getBookings = (): Promise<Booking[]> => apiClient.get('/api/tenant/bookings').then(({ data }) => data.bookings);

const getProperty = (): Promise<Property> => apiClient.get('/api/property').then(({ data }) => data.property);

const updateCalendar = (edits: { add: DateString[]; remove: DateString[] }): Promise<Calendar> => {
  return apiClient
    .put('/api/calendar', edits)
    .then(({ data }) => data)
    .catch(({ response }) => {
      throw response.data.error.message;
    });
};

const logout = (): Promise<void> =>
  apiClient.delete('/api/sessions').then(() => {
    return;
  });

const updateTenantProfile = (tenant: Tenant): Promise<Tenant> =>
  apiClient
    .put('/api/tenant', { tenant: remapTenantToBackend(tenant) })
    .then(({ data }) => {
      return remapTenant(data.tenant);
    })
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const completeQuickstart = (): Promise<true> =>
  apiClient
    .post('/api/tenant/quickstart')
    .then(() => true as const)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const confirmUnitDetails = (): Promise<Tenant> =>
  apiClient
    .post('/api/tenant/unit_confirmation')
    .then(({ data }) => remapTenant(data.tenant))
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const rejectUnitDetails = (): Promise<Tenant> =>
  apiClient
    .delete('/api/tenant/unit_confirmation')
    .then(({ data }) => remapTenant(data.tenant))
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const getOnboardingApplication = (): Promise<OnboardingApplication<ResidentOnboardingStepType>> =>
  apiClient
    .get('/api/onboarding_application')
    .then(({ data }) => data.application)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const startOnboardingStep = (step: StartStepPayload): Promise<true> =>
  apiClient
    .post('/api/onboarding_application/start_step', step)
    .then(() => true as const)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const completeOnboardingStep = (
  step: CompleteStepPayload
): Promise<OnboardingApplication<ResidentOnboardingStepType>> => {
  // use 'multipart/form-data' so that steps can send files
  const formData = makeFormData(step);

  return apiClient
    .post('/api/onboarding_application/complete_step', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(({ data }) => data.application)
    .catch(({ response }) => {
      throw response.data.error.message;
    });
};

const viewHomesharingLicenseExpirationWarning = (): Promise<Tenant> =>
  apiClient.post('/api/tenant/homesharing_license_expiration_warning').then(({ data }) => remapTenant(data.tenant));

const viewLeaseEndWarning = (): Promise<Tenant> =>
  apiClient.post('/api/tenant/lease_end_warning').then(({ data }) => remapTenant(data.tenant));

const getAvailabilityCalendarDemand = (): Promise<{ low: DateString[]; high: DateString[] }> =>
  apiClient
    .get('/api/tenant/calendar_demand')
    .then(({ data }) => data)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

export {
  getTenant,
  getCalendar,
  getBookings,
  getProperty,
  createPassword,
  createSession,
  updateCalendar,
  requestPasswordReset,
  resetPassword,
  logout,
  updateTenantProfile,
  completeQuickstart,
  confirmUnitDetails,
  rejectUnitDetails,
  startOnboardingStep,
  completeOnboardingStep,
  getOnboardingApplication,
  viewHomesharingLicenseExpirationWarning,
  viewLeaseEndWarning,
  getAvailabilityCalendarDemand
};
