import React, { FunctionComponent } from 'react';
import { rem } from 'csx';
import { Color, mediaStyle } from 'styles/base';
import { flex } from 'styles/layout';
import injectStyle from 'hoc/injectStyle';
import { AppBody, Flex } from 'components/Layout';
import { H1, H4 } from 'components/Typography';
import { ButtonSecondary } from 'components/Buttons';
import { LogoHorizontal } from 'components/Logos';

/**
 * @prop positionContent  Display the 404 message and illustration at the top
 * of the container ('top') or fill the space by placing the text in the center
 * and illustration on the bottom ('stretch').
 */
interface Props {
  handleReturnHome: () => void;
}

const NotFoundBody = injectStyle(
  AppBody,
  mediaStyle({
    shared: {
      display: 'flex',
      flexDirection: 'column'
    },
    desktop: {
      justifyContent: 'center'
    },
    mobile: {
      justifyContent: 'stretch'
    }
  })
);

const MessageWrapper = injectStyle(
  'div',
  mediaStyle({
    shared: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    desktop: {
      flexGrow: 0
    },
    mobile: {
      flexGrow: 1
    }
  })
);

const MultilineHeading = injectStyle(H1, {
  ...flex,
  flexDirection: 'column',
  alignItems: 'center',
  color: Color.textDark,
  marginTop: rem(0.5),
  marginBottom: rem(2)
});

const ButtonWrapper = injectStyle(
  'div',
  mediaStyle({
    shared: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    desktop: {
      padding: rem(2)
    },
    mobile: {
      padding: rem(1)
    }
  })
);

const PageNotFound: FunctionComponent<Props> = ({ handleReturnHome }) => (
  <NotFoundBody>
    <MessageWrapper>
      <LogoHorizontal height={45} width={71} />
      <H4 textColor="attention" marginTop="3rem" marginBottom="0.5rem">
        404
      </H4>
      <MultilineHeading>
        <span>Sorry! We couldn’t find the</span>
        <span>page you were looking for.</span>
      </MultilineHeading>
    </MessageWrapper>
    <Flex flexDirection="column" alignItems="center">
      <ButtonWrapper>
        <ButtonSecondary onClick={handleReturnHome}>Return to Home</ButtonSecondary>
      </ButtonWrapper>
    </Flex>
  </NotFoundBody>
);

export default PageNotFound;
