/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ComponentType, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import { classes, style } from 'typestyle';
import isPropValid from '@emotion/is-prop-valid';
import { pickBy } from 'lodash';
import { CSS } from 'styles/types';

type Props<C extends keyof JSX.IntrinsicElements | ComponentType> = C extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[C]
  : C extends ComponentType<infer P>
  ? P
  : never;

type StyleFn<P> = (props: P) => CSS | string;

/**
 * HOC for adding styles to a component or intrinsic html element.
 *
 * @typeparam C  Type of the component to be styled.
 * @typeparam StyleProps  Additional props that will extend the returned
 * component's prop interface. Can be used with a style generating
 * function to add props specifically for conditional styling.
 * @param component  A class component, functional component, or string.
 * A string value must be one of the built in JSX components such as
 * 'div', 'input', 'h3', etc.
 * @param addedStyles  The styling rules that will be injected into the
 * component. These rules can be provided in one of three formats:
 * - A typestyle NestedCSSProperties object.
 * - A string containing a css class or space-separated classes.
 * - A function that returns one of the two other options, based on
 *   the component's props.
 * @return StyledComponent  The resulting function component takes the same
 * props, plus any new props specified in StyleProps. When the component
 * is rendered it will include additional css classes emitted by
 * typestyle.
 */
// ComponentType defaults its type argument to `{}` which means that
// without the `any` we're checking `FunctionComponent<Foo> extends
// ComponentType<{}>` which is a type error. By expanding the prop type
// space to `any` we allow `FunctionComponent<Foo>` without any loss of
// specificity.
export const injectStyle = <C extends keyof JSX.IntrinsicElements | ComponentType<any>, StyleProps extends object = {}>(
  component: C,
  addedStyles: CSS | string | StyleFn<Props<C> & StyleProps>,
  opts?: { filterProps: boolean }
): ForwardRefExoticComponent<PropsWithoutRef<Props<C> & StyleProps> & RefAttributes<HTMLElement>> => {
  const compiledOrFn = typeof addedStyles === 'object' ? style(addedStyles) : addedStyles;

  const styledComponent = React.forwardRef<HTMLElement, Props<C> & StyleProps>((props, ref) => {
    // If the `component` arg is an html intrinsic like 'div' then we want to
    // filter out added props and only pass through props that are valid to the
    // DOM. Otherwise we usually don't care about passing down some extra
    // props, but there are some special cases such as SVG components where it
    // might be necessary to explicitly flag filtering with the opts flag.
    // Filtering doesn't protect against overriding valid DOM
    // attributes, like `color`. See https://github.com/emotion-js/emotion/blob/master/packages/is-prop-valid/src/props.js
    // for the full list of props checked. Note that `pickBy` is going to trash
    // the type of this variable, but we have to ignore the type for the next
    // line anyways, so that's ok.
    const filterProps = !!opts?.filterProps || typeof component === 'string';
    const filteredProps = filterProps ? pickBy(props, (_value, key) => isPropValid(key)) : props;

    // The way typescript calculates Props<C> allows for an empty object
    // case where className and children are not keys. Realistically
    // this shouldn't be a problem. StyledComponents also uses an `any`
    // to get around this issue.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { className, children, ...rest } = filteredProps as any;
    const compiledOrObj = typeof compiledOrFn === 'function' ? compiledOrFn(props) : compiledOrFn;
    const compiled = typeof compiledOrObj === 'object' ? style(compiledOrObj) : compiledOrObj;
    return React.createElement(component, { className: classes(className, compiled), ref: ref, ...rest }, children);
  });
  styledComponent.displayName = 'StyledComponent';

  return styledComponent;
};

export default injectStyle;
