import axios from 'axios';
import applyConverters from 'axios-case-converter';
import {
  Admin,
  HomesharingApplicationStatus,
  OnboardingApplication,
  Property,
  AmenitiesChecklistData
} from 'api/types';

const apiClient = applyConverters(axios.create());

const login = (email: string, password: string): Promise<Admin> =>
  apiClient
    .post('/api/manager/sessions', { session: { email, password } })
    .then(({ data }) => data.admin)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const logout = (): Promise<void> =>
  apiClient.delete('/api/manager/sessions').then(() => {
    return;
  });

const getApplicants = (): Promise<HomesharingApplicationStatus[]> =>
  apiClient
    .get('/api/manager/onboarding_application')
    .then(({ data }) => data.applications)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const getHomesharingApplication = (applicationId: number): Promise<OnboardingApplication> =>
  apiClient
    .get(`/api/manager/onboarding_application/${applicationId}`)
    .then(({ data }) => data.application)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const getAccount = (): Promise<Admin> =>
  apiClient
    .get('/api/manager/account')
    .then(({ data }) => data.admin)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const getProperties = (): Promise<Property[]> =>
  apiClient
    .get('/api//api/manager/properties')
    .then(({ data }) => data.properties)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const approveForHomeSharing = (applicationId: number): Promise<OnboardingApplication> =>
  apiClient
    .post(`/api/manager/onboarding_application/${applicationId}/approval`)
    .then(({ data }) => data.application)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const completeWalkthrough = (
  applicationId: number | string,
  stepId: number | string,
  payload?: {
    data: AmenitiesChecklistData;
    requestChanges: boolean;
  }
): Promise<OnboardingApplication> =>
  apiClient
    .post(`/api/manager/onboarding_application/${applicationId}/step/${stepId}/complete`, {
      ...payload,
      stepType: 'APARTMENT_WALKTHROUGH'
    })
    .then(({ data }) => data.application)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const updateApartmentWalkthroughStepData = (
  applicationId: number | string,
  stepId: number | string,
  checklist: AmenitiesChecklistData
): Promise<AmenitiesChecklistData> =>
  apiClient
    .put(`/api/manager/onboarding_application/${applicationId}/step/${stepId}`, {
      stepType: 'APARTMENT_WALKTHROUGH',
      data: checklist
    })
    .then(({ data }) => data.step.data)
    .catch(({ response }) => {
      throw response.data.error.message;
    });

const getListingNotes = (applicationId: number | string): Promise<string> =>
  apiClient
    .get(`/api/manager/onboarding_application/${applicationId}/listing_notes`)
    .then(({ data }) => data.listingNotes);

const updateListingNotes = (applicationId: number | string, notes: string): Promise<string> =>
  apiClient
    .put(`/api/manager/onboarding_application/${applicationId}/listing_notes`, {
      listingNotes: notes
    })
    .then(({ data }) => data.listingNotes);

const deactivateApplicant = (applicationId: number | string): Promise<HomesharingApplicationStatus[]> =>
  apiClient
    .delete(`/api/manager/onboarding_application/${applicationId}/activation`)
    .then(({ data }) => data.applications);

export {
  login,
  logout,
  getApplicants,
  getHomesharingApplication,
  getAccount,
  getProperties,
  approveForHomeSharing,
  updateApartmentWalkthroughStepData,
  completeWalkthrough,
  getListingNotes,
  updateListingNotes,
  deactivateApplicant
};
