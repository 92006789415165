import { AppState } from 'store';

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
}

type Action = SetIsInitialized | SetIsAuthenticated;

interface SetIsInitialized {
  type: 'SESSION/SET_IS_INITIALIZED';
  payload: boolean;
}

interface SetIsAuthenticated {
  type: 'SESSION/SET_IS_AUTHENTICATED';
  payload: boolean;
}

const IS_AUTHENTICATED_STORAGE_KEY = 'tenantAuthenticated';

const hydrateIsAuthenticated = () => localStorage.getItem(IS_AUTHENTICATED_STORAGE_KEY) === 'true';

const persistIsAuthenticated = (isAuthenticated: boolean) =>
  localStorage.setItem(IS_AUTHENTICATED_STORAGE_KEY, isAuthenticated ? 'true' : 'false');

const initializeApp = (): SetIsInitialized => ({
  type: 'SESSION/SET_IS_INITIALIZED',
  payload: true
});
const setIsAuthenticated = (isAuthenticated: boolean): SetIsAuthenticated => ({
  type: 'SESSION/SET_IS_AUTHENTICATED',
  payload: isAuthenticated
});

const getState = (state: AppState): State => state.session;

const getIsInitialized = (appState: AppState): boolean => getState(appState).isInitialized;

const getIsAuthenticated = (appState: AppState): boolean => getState(appState).isAuthenticated;

const initialState = {
  isAuthenticated: hydrateIsAuthenticated(),
  isInitialized: false
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'SESSION/SET_IS_INITIALIZED':
      return { ...state, isInitialized: action.payload };
    case 'SESSION/SET_IS_AUTHENTICATED':
      persistIsAuthenticated(action.payload);
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
};

export type {
  State,
  Action
}

export { reducer, initializeApp, setIsAuthenticated, getIsInitialized, getIsAuthenticated };
