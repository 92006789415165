import React, { FunctionComponent } from 'react';
import { classes, style } from 'typestyle';
import { extractFlexCSS, extractMarginCSS } from 'styles/base';
import { FlexCSS, MarginCSS } from 'styles/types';
import injectStyle from 'hoc/injectStyle';
import withMarginProps from 'hoc/withMarginProps';
import Styles, { columnStack, rowStack } from 'styles/layout';
import { Small } from 'components/Typography';

export const Flex = injectStyle('div', (styleProps: FlexCSS & MarginCSS) => ({
  display: 'flex',
  ...extractFlexCSS(styleProps),
  ...extractMarginCSS(styleProps)
}));

type StackProps = { spacing: number | string } & FlexCSS & MarginCSS;

export const Stack = injectStyle('div', ({ spacing, flexDirection, ...styleProps }: StackProps) => ({
  display: 'flex',
  ...extractFlexCSS(styleProps),
  ...extractMarginCSS(styleProps),
  ...(flexDirection === 'row' ? rowStack(spacing) : columnStack(spacing))
}));

export const SectionDivider = withMarginProps(injectStyle('div', Styles.sectionDivider));

interface HorizontalRuleProps {
  childPos: 'start' | 'center' | 'end';
}

export const HorizontalRule = injectStyle('div', ({ childPos }: HorizontalRuleProps) =>
  classes(
    childPos === 'start' && style({ $nest: { '&::before': { display: 'none' } } }),
    childPos === 'end' && style({ $nest: { '&::after': { display: 'none' } } }),
    Styles.horizontalRule
  )
);

interface LabeledInfoProps {
  label: string;
  className?: string;
}

export const Labeled: FunctionComponent<LabeledInfoProps> = ({ label, children }) => (
  <div>
    <Small marginBottom="0.5rem">{label}</Small>
    {children}
  </div>
);

interface AppBodyProps {
  containerClassName?: string;
  className?: string;
  forwardRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

export const AppBody: FunctionComponent<AppBodyProps> = ({ containerClassName, className, forwardRef, ...props }) => (
  <div ref={forwardRef} className={classes('app-body-container', containerClassName, Styles.appContainer)}>
    <div className={classes('app-body-content', className, Styles.appContent)} {...props} />
  </div>
);
