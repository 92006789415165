import React, { FunctionComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { History, createBrowserHistory } from 'history';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from 'CommunityApp/styles/theme';
import store from 'CommunityApp/store/store';
import ErrorBoundary from 'CommunityApp/layout/ErrorBoundary';
import Routes from 'CommunityApp/routes/Routes';
import ErrorPage from 'CommunityApp/pages/ErrorPage';

interface Props {
  history?: History;
}

const CommunityApp: FunctionComponent<Props> = (props) => {
  const history = props.history ?? createBrowserHistory();
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ErrorBoundary fallback={ErrorPage}>
          <ReduxProvider store={store}>
            <Routes />
          </ReduxProvider>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  );
};

export default CommunityApp;
