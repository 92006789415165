import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { classes } from 'typestyle';
import injectStyle from 'hoc/injectStyle';
import { mediaStyle } from 'styles/base';
import { CSS } from 'styles/types';
import Styles from 'styles/buttons';
import { ComponentProps } from 'utils/types';

/**
 * @prop fullWidth - Most buttons are either a fixed width, or stretch to fill
 * all horizontal space. This prop sets which situations to use what styles.
 */
interface LargeButtonStyleProps {
  fullWidth?: 'always' | 'never' | 'mobile' | 'desktop';
}

const fullWidthStyle: CSS = {
  width: '100%'
};

const applyLargeButtonStyles = (baseButton: string) => ({ fullWidth = 'mobile' }: LargeButtonStyleProps) => {
  if (fullWidth === 'never') {
    return baseButton;
  }

  const fullWidthClass = mediaStyle(
    fullWidth === 'always' ? { shared: fullWidthStyle } : { [fullWidth]: fullWidthStyle }
  );
  return classes(fullWidthClass, baseButton);
};

export const ButtonPrimary = injectStyle('button', applyLargeButtonStyles(Styles.buttonPrimary));

export const ButtonSecondary = injectStyle('button', applyLargeButtonStyles(Styles.buttonSecondary));

const Submit: FunctionComponent<JSX.IntrinsicElements['input']> = (props) => <input type="submit" {...props} />;

export const SubmitPrimary = injectStyle(Submit, applyLargeButtonStyles(Styles.buttonPrimary), { filterProps: true });

export const SubmitSecondary = injectStyle(Submit, applyLargeButtonStyles(Styles.buttonSecondary), {
  filterProps: true
});

export const ButtonText = injectStyle('button', Styles.buttonText);

export const ButtonSubtleText = injectStyle('button', Styles.buttonSubtleText);

export const ButtonRadio = injectStyle('button', Styles.buttonRadio);

export const NavButton = injectStyle('button', Styles.navButton);

/**
 * @prop fullWidth - See LargeButtonStyleProps
 * @prop to - route to go to when button is pressed
 */
type NavigationButtonProps = ComponentProps<typeof ButtonPrimary> & { to: string };

export const NavigationButtonPrimary: FunctionComponent<NavigationButtonProps> = ({ to, ...props }) => {
  const history = useHistory();
  const handleNavigation = () => history.push(to);
  return <ButtonPrimary {...props} onClick={handleNavigation} />;
};

export const NavigationButtonSecondary: FunctionComponent<NavigationButtonProps> = ({ to, ...props }) => {
  const history = useHistory();
  const handleNavigation = () => history.push(to);
  return <ButtonSecondary {...props} onClick={handleNavigation} />;
};
