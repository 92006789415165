import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import AppRoute from 'CommunityApp/routes/AppRoute';
import AuthRoute from 'CommunityApp/routes/AuthRoute';
import Login from 'CommunityApp/pages/Login';
import PageNotFound from 'CommunityApp/pages/PageNotFound';
import Bookings from 'CommunityApp/pages/Bookings';

const BASE_PATH = '/community';
const buildPath = (path: string): string => `${BASE_PATH}${path}`;

export const path = {
  home: buildPath(''),
  login: '/admin/login'
};

const Routes: FunctionComponent = () => {

  const location = useLocation();
  if (location.pathname === '/') {
    return (
      <Redirect to={BASE_PATH}  />
    )
  }

  return (
    <Switch>
      <AuthRoute exact path={path.login} component={Login} />
      <AppRoute exact path={path.home} component={Bookings} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
