import 'normalize.css';
import 'css/community-app.css';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';
import CommunityApp from 'CommunityApp/CommunityApp';
import { prodHostnames, sentry, gaTrackingCode } from 'CommunityApp/api/externalURLS';

const history = createBrowserHistory();

if (prodHostnames.includes(window.location.hostname)) {
  if (!sentry.disable) {
    console.log(`monitoring ${window.location.hostname}`)
    Sentry.init({
      dsn: sentry.dsn,
      environment: sentry.environment
    });
  }

  ReactGA.initialize(gaTrackingCode as string);
  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

ReactDOM.render(React.createElement(CommunityApp, { history }), document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
