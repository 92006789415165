import React, { FunctionComponent } from 'react';
import { style } from 'typestyle';
import { LogoHorizontal } from 'components/Logos';

const centerContent = style({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
});
const loadingContainer = style({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const LoadingScreen: FunctionComponent = () => (
  <div className={centerContent}>
    <div className={loadingContainer}>
      <LogoHorizontal />
    </div>
  </div>
);

export default LoadingScreen;
