export const guestyHostName = 'https://app.guesty.com';
export const gaTrackingCode = 'UA-149594163-1';
export const feedbackEmail  = 'feedback@sentral.com';

export const sentry = {
  dsn: 'https://cf0dc0576805406b9962432ffc6b737b@o272972.ingest.sentry.io/6316855',
  environment: 'production',
  disable: false
}

export const prodHostnames = [
  'sentralos-community-app.sentral.com'
]
