import { stylesheet } from 'typestyle';
import { Color } from 'styles/base';
import { CSS } from 'styles/types';
import { flex } from 'styles/layout';
import { largeButtonText, smallButtonText } from 'styles/typography';

const buttonBase: CSS = {
  ...flex,
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  justifyContent: 'center'
};

const largeButton: CSS = {
  ...largeButtonText,
  minWidth: 203,
  height: 56,
  borderRadius: 16,
  paddingLeft: 16,
  paddingRight: 16
};

export const buttonPrimary: CSS = {
  ...buttonBase,
  ...largeButton,
  backgroundColor: Color.buttonDark,
  color: Color.white,
  $nest: {
    '&:hover': {
      border: `solid 2px ${Color.buttonDark}`,
      backgroundColor: Color.buttonDarkHover,
      color: Color.buttonDark
    },
    '&&:active': {
      backgroundColor: Color.buttonDarkActive
    },
    '&&&:disabled': {
      backgroundColor: Color.buttonDarkDisabled,
      color: Color.moon,
      cursor: 'default'
    },
    '&&&:disabled:hover': {
      border: 'none',
      backgroundColor: Color.buttonDarkDisabled,
      color: Color.moon
    }
  }
};

export const buttonSecondary: CSS = {
  ...buttonBase,
  ...largeButton,
  backgroundColor: Color.white,
  border: `solid 2px ${Color.buttonDark}`,
  color: Color.buttonDark,
  $nest: {
    '&:hover': {
      backgroundColor: Color.buttonDarkHover
    },
    '&&:active': {
      border: `solid 2px ${Color.buttonDarkActive}`,
      color: Color.buttonDarkActive
    },
    '&&&:disabled': {
      border: `solid 2px ${Color.buttonDarkDisabled}`,
      color: Color.buttonDarkDisabled,
      cursor: 'default'
    }
  }
};

export const buttonText: CSS = {
  ...buttonBase,
  ...smallButtonText,
  backgroundColor: 'transparent',
  color: Color.blueSky,
  padding: 0,
  $nest: {
    '&:hover': {
      color: Color.borderGrey
    },
    '&&:active': {
      color: Color.white
    },
    '&&&:disabled': {
      color: Color.moon,
      opacity: 0.5,
      cursor: 'default'
    }
  }
};

export const buttonSubtleText: CSS = {
  ...buttonBase,
  ...smallButtonText,
  backgroundColor: 'transparent',
  color: Color.moon,
  padding: 0,
  $nest: {
    '&:hover': {
      opacity: 0.8
    },
    '&&:active': {
      color: Color.eclipse
    },
    '&&&:disabled': {
      color: Color.moon,
      opacity: 0.5,
      cursor: 'default'
    }
  }
};

export const navButton: CSS = {
  ...buttonBase,
  backgroundColor: 'transparent',
  flexDirection: 'column',
  fontFamily: 'Prata',
  fontSize: 18,
  lineHeight: '22px',
  opacity: 0.6
};

/* The radio button looks like a primary button in its disabled state, and a
 * secondary button in its enabled state. This is to communicate that in a
 * group of buttons, the _disabled_ one is the currently selected option, and
 * the enabled ones are the radio options that could be selected instead. For
 * accessibility it would probably be better to implement this as a radio
 * input, but styling radio inputs is fiddly.
 */
export const buttonRadio: CSS = {
  ...buttonSecondary,
  ...smallButtonText,
  color: Color.blueSky,
  minWidth: 'auto',
  height: 40,
  $nest: {
    ...buttonSecondary['$nest'],
    '&&&:disabled': {
      backgroundColor: Color.blueSky,
      border: 'none',
      color: Color.white,
      cursor: 'default'
    }
  }
};

export default stylesheet({
  buttonPrimary,
  buttonSecondary,
  buttonText,
  buttonSubtleText,
  buttonRadio,
  navButton
});
