import { stylesheet } from 'typestyle';
import { rem } from 'csx';
import { Color } from 'styles/base';
import { CSS, MediaCSS } from 'styles/types';
import { mediaStyle } from 'styles/base';

export const flex: CSS = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  alignContent: 'stretch'
};

/**
 * Create styles for a container that puts a vertical margin of `spacing`
 * between each child. We use the double className `&&` trick to increase
 * specificity of the child selector rule. This should ensure that we override
 * the child element's margin styles, which is the desired effect if you're
 * using a Stack.
 */
export const columnStack = (spacing: number | string): CSS => ({
  flexDirection: 'column',
  $nest: {
    '&& > *': {
      marginTop: 0,
      marginBottom: spacing
    },
    '&& > *:last-child': {
      marginBottom: 0
    }
  }
});

/**
 * Create styles for a container that puts a horizontal margin of `spacing`
 * between each child. See [[columnStack]].
 */
export const rowStack = (spacing: number | string): CSS => ({
  flexDirection: 'row',
  $nest: {
    '&& > *': {
      marginLeft: 0,
      marginRight: spacing
    },
    '&& > *:last-child': {
      marginRight: 0
    }
  }
});

export const sectionDivider: CSS = {
  borderBottomStyle: 'solid',
  borderWidth: 'thin',
  borderColor: Color.borderGrey
};

export const appContainer: MediaCSS = {
  shared: {
    ...flex,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1
  },
  mobile: {
    overflowY: 'auto',
    overflowX: 'hidden'
  }
};

export const horizontalRule: CSS = {
  display: 'flex',
  $nest: {
    '&::before, &::after': {
      content: '" "',
      display: 'flex',
      flexGrow: 1,
      flexShrink: 1,
      borderBottomColor: Color.fog,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      margin: 'auto'
    },
    '&::before': {
      marginRight: 8
    },
    '&::after': {
      marginLeft: 8
    }
  }
};

export const appContent: MediaCSS = {
  desktop: {
    width: rem(45)
  },
  mobile: {
    width: '100%'
  }
};

export default {
  ...stylesheet({ flex, sectionDivider, horizontalRule }),
  appContainer: mediaStyle(appContainer),
  appContent: mediaStyle(appContent)
};
