import { style, classes } from 'typestyle';
import { injectStyle } from 'hoc/injectStyle';
import { Color, extractMarginCSS, extractTypographyCSS } from 'styles/base';
import { MarginCSS as MarginProps, TypographyCSS as TypographyProps } from 'styles/types';
import Styles from 'styles/typography';

type TextStyleProps = { textColor?: keyof typeof Color } & MarginProps & TypographyProps;

const applyStylePropsFor = (baseHeading: string) => ({ textColor, ...props }: TextStyleProps) => {
  return classes(
    textColor !== undefined && style({ color: Color[textColor] }),
    style(extractMarginCSS(props)),
    style(extractTypographyCSS(props)),
    baseHeading
  );
};

export const H1 = injectStyle('h1', applyStylePropsFor(Styles.headingOne));
export const H1S = injectStyle('h1', applyStylePropsFor(Styles.headingOneS));

export const H2 = injectStyle('h2', applyStylePropsFor(Styles.headingTwo));

export const H3 = injectStyle('h3', applyStylePropsFor(Styles.headingThree));

export const H4 = injectStyle('h4', applyStylePropsFor(Styles.headingFour));

export const H5 = injectStyle('h5', applyStylePropsFor(Styles.headingFive));

export const P = injectStyle('p', applyStylePropsFor(Styles.paragraph));
export const PS = injectStyle('p', applyStylePropsFor(Styles.paragraphS));

export const Small = injectStyle('small', applyStylePropsFor(Styles.small));

export const Tiny = injectStyle('h5', applyStylePropsFor(Styles.tiny));

export const Label = injectStyle('label', applyStylePropsFor(Styles.label));

type StyledTextProps = { textColor?: keyof typeof Color } & TypographyProps;

export const StyledText = injectStyle('span', ({ textColor, ...props }: StyledTextProps) =>
  classes(textColor !== undefined && style({ color: Color[textColor] }), style(extractTypographyCSS(props)))
);
