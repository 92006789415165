import React, { FunctionComponent } from 'react';
import { keyframes } from 'typestyle';
import injectStyle from 'hoc/injectStyle';
import { Color } from 'styles/base';
import { SVGProps } from 'utils/types/SVG';
import { ReactComponent as LogoMarkSVG } from 'logos/logo_mark.svg';
import { ReactComponent as LogoAnimatedMarkSVG } from 'logos/logo_animated_mark.svg';
import { ReactComponent as SentralLogoSVG } from 'logos/sentral_logo.svg';

export const LogoHorizontal: FunctionComponent<SVGProps> = ({ height, width, ...props }) => (
  <SentralLogoSVG width={width ?? 179} height={height ?? 41} {...props} />
);

export const LogoMark: FunctionComponent<SVGProps> = ({ height, width, ...props }) => (
  <LogoMarkSVG width={width ?? 130} height={height ?? 82} {...props} />
);

const dashAnimation = keyframes({
  from: { strokeDashoffset: 800 },
  to: { strokeDashoffset: 0 }
});

// Note that IDs used to style parts of the SVG are prefixed with the svg file
// name. This is the default setting of SVGR, and can be changed in the webpack
// config.
export const LogoAnimatedMark = injectStyle(LogoAnimatedMarkSVG, {
  height: 28,
  width: 45,
  $nest: {
    '& #logo_animated_mark_svg__logo-path': {
      fill: Color.white
    },
    '& #logo_animated_mark_svg__logo-animation-mask': {
      strokeDasharray: 400,
      strokeDashoffset: 800,
      animation: `${dashAnimation} 4s linear infinite`,
      opacity: 0.75,
      stroke: Color.blueSky
    }
  }
});
